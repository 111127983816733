/* eslint-disable react/prop-types */
import Image from "next/image";
import { useTransition, useState, lazy, useEffect } from "react";
import { VideoJsPlayerOptions, VideoJsPlayerPluginOptions } from "video.js";
import PlayIcon from "@public/assets/icons/play-circle.svg";
import styles from "./lazyVideo.module.scss";
import classNames from "classnames";

const HeavyVideoComponent = lazy(() => import("./component"));

type BaseProps = {
  vjsOptions: VideoJsPlayerOptions | VideoJsPlayerPluginOptions;
  jobId?: number | null;
  videoClassName?: string;
  className?: string;
};

const LazyVideoComponent: React.FC<BaseProps> = ({ vjsOptions, jobId, videoClassName, className }) => {
  const [, startTransition] = useTransition();
  const [load, setLoad] = useState(false);
  const [detailJobId, setDetailJobId] = useState(0);

  const handleLoadVideo = () => {
    setDetailJobId(jobId as number);
    startTransition(() => {
      setLoad(true);
    });
  };

  useEffect(() => {
    if (detailJobId !== jobId) {
      setLoad(false);
    }
  }, [detailJobId, jobId]);

  if (load) {
    return <HeavyVideoComponent className={videoClassName} vjsOptions={vjsOptions} key={`lazyVideo_${jobId}`} />;
  }

  return (
    <div className={classNames(styles.imgContainer, styles[`${className}`])} onClick={handleLoadVideo}>
      <Image src={vjsOptions.poster} alt="poster-video" layout="fill" objectFit="contain" loading="lazy" />
      <div className={styles.playButton}>
        <PlayIcon alt="play video" height={58} width={58} loading="lazy" />
      </div>
    </div>
  );
};

export default LazyVideoComponent;
