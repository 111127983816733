import React, { useState } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { Button, Carousel, Modal } from "antd";
import Device from "@components/common/device/component";
import CardDescription from "@components/landing/cardDescription/component";
import CustomVideo from "@components/common/customVideo/component";
// import SocialMedia from "@components/landing/socialMedia/component";
import CompanyButton from "@components/landing/companyButton/component";
import { assetsType, settingsHeader, settingsVideoOptions } from "@constants";
import { TLanding } from "#types/landing";
import { fillUrlUtms } from "@utils/url";
import styles from "./companyHeader.module.scss";
import classNames from "classnames";

type Props = {
  headerData: TLanding;
  t: TFunction;
};

const CompanyHeader: React.FC<Props> = ({ headerData, t }) => {
  const [showVideo, setShowVideo] = useState(false);

  const { initialHeader, workWithUs, slideSettings, generalPageSettings, companyName } = headerData;
  const { slides } = slideSettings;
  const {
    headerBackgroundImage,
    headerMobileImage,
    headerTitle,
    headerText,
    mod3ButtonVisible,
    headerVideoUrl,
    headerVideoSmallThumbnailUrl
  } = initialHeader;

  const {
    workWithUsTitle,
    workWithUsDescription,
    videoWorkWithUsUrl,
    switchWorkWithUs,
    workWithUsBackgroundColor,
    workWithUsImageUrl
  } = workWithUs;

  const { query } = useRouter();

  return (
    <div className={styles["companyHeader"]}>
      {slides.length > 0 ? (
        <Carousel {...settingsHeader}>
          {slides.map(({ assetUrl, linkSlide, assetMobileUrl }, key) => (
            <a
              href={(linkSlide && fillUrlUtms(linkSlide, query)) || "#"}
              className={styles["companyHeader_banner"]}
              key={key}
            >
              <Device>
                {({ isMobile }) => {
                  // TODO: review what is the correct objectFit
                  if (isMobile)
                    return (
                      <Image
                        src={assetMobileUrl}
                        alt="Banner"
                        layout="fill"
                        objectFit="cover"
                        priority
                        height={"100%"}
                      />
                    );
                  return <Image src={assetUrl} alt="Banner" layout="fill" height={"100%"} priority />;
                }}
              </Device>
            </a>
          ))}
        </Carousel>
      ) : (
        <div className={styles["companyHeader_banner"]}>
          {headerText && (
            <div className={styles["companyHeader_banner-text"]} style={{ color: generalPageSettings.secondaryColor }}>
              {headerTitle && <label style={{ fontFamily: generalPageSettings.titleFontUrl }}>{headerTitle}</label>}
              <p style={{ fontFamily: generalPageSettings.subtitleFontUrl }}>{headerText}</p>
              {headerVideoUrl && (
                <div className={styles["companyHeader_banner_video"]} onClick={() => setShowVideo(!showVideo)}>
                  <i className="las la-play-circle" />
                </div>
              )}
              {mod3ButtonVisible && (
                <Button
                  // href={`${window.location.origin}${t('routes:jobs')}${
                  //   window.location.pathname
                  // }`}
                  style={{
                    background: generalPageSettings.mainColor,
                    color: generalPageSettings.secondaryColor
                  }}
                >
                  {t("registration:landing.seeJobOffers") as string}
                </Button>
              )}
            </div>
          )}
          {headerBackgroundImage || headerMobileImage ? (
            <Device>
              {({ isMobile }) => {
                // TODO: review what is the correct objectFit
                if (isMobile)
                  return (
                    <Image
                      src={headerMobileImage ? headerMobileImage : headerBackgroundImage}
                      alt="Banner"
                      layout="fill"
                      objectFit="cover"
                      priority
                    />
                  );
                return <Image src={headerBackgroundImage} alt="Banner" layout="fill" objectFit="cover" priority />;
              }}
            </Device>
          ) : (
            <div
              style={{ background: generalPageSettings.mainColor }}
              className={styles["companyHeader_banner-bannerColor"]}
            ></div>
          )}
          <Modal visible={showVideo} onCancel={() => setShowVideo(!showVideo)} footer={false}>
            <CustomVideo
              className={classNames(styles["companyHeader_banner_modalVideo"], "display-video__player")}
              vjsOptions={{
                ...settingsVideoOptions,
                src: headerVideoUrl,
                poster: headerVideoSmallThumbnailUrl
              }}
            />
          </Modal>
        </div>
      )}

      {companyName && (
        <h1 className={styles["companyHeader_title"]}>{t<string>("registration:landing.title", { companyName })}</h1>
      )}
      {/* TODO: review if this buttons in SocialMedia component are necessary */}
      {/* {socialMediaSignUpBar.barEnabled && (
        <SocialMedia
          t={t}
          data={companySocialMedia}
          barColor={socialMediaSignUpBar.barColor}
          barTextColor={socialMediaSignUpBar.barTextColor}
        />
      )} */}
      {switchWorkWithUs && (videoWorkWithUsUrl || workWithUsImageUrl) && (
        <div className={styles["companyHeader_description"]}>
          <CardDescription
            key={`cardDescription_${query.companySlug}`}
            styles={{
              background: workWithUsBackgroundColor,
              fontFamily: generalPageSettings.titleFontUrl
            }}
            title={workWithUsTitle}
            description={workWithUsDescription}
            // btnText={btnTextWorkWithUs}
            type={videoWorkWithUsUrl ? assetsType.video : assetsType.image}
            videoConfig={
              videoWorkWithUsUrl && {
                ...settingsVideoOptions,
                src: videoWorkWithUsUrl.url,
                poster: videoWorkWithUsUrl.smallThumbnailUrl
              }
            }
            url={workWithUsImageUrl}
          >
            <CompanyButton
              link={workWithUs.btnLinkWorkWithUs}
              background={workWithUs.btnColorWorkWithUs}
              color={workWithUs.btnTextColorWorkWithUs}
              text={workWithUs.btnTextWorkWithUs}
            />
          </CardDescription>
        </div>
      )}
    </div>
  );
};

export default withTranslation("registration")(CompanyHeader);
